/* eslint-disable */
// @ts-nocheck
// This file was generated by lezer-generator. You probably shouldn't edit it.
export const StartTag = 1,
	StartCloseTag = 2,
	MissingCloseTag = 3,
	mismatchedStartCloseTag = 4,
	incompleteStartCloseTag = 5,
	Document = 6,
	Text = 7,
	Brace = 8,
	Element = 9,
	EndTag = 10,
	OpenTag = 11,
	If = 12,
	Unless = 13,
	TagName = 14,
	CloseTag = 15,
	SelfClosingTag = 16,
	TransformerDelimiter = 17,
	Transformer = 18,
	MismatchedCloseTag = 19
